<template>
    <div class="form-field-password">
        <input
            v-if="showPassword"
            autocomplete="on"
            class="form-field-password__input"
            :class="{ invalid }"
            v-bind="$attrs"
            :value="modelValue"
            type="text"
            @input="inputEmitter"
        />
        <input
            v-else
            autocomplete="on"
            class="form-field-password__input"
            :class="{ invalid }"
            v-bind="$attrs"
            :value="modelValue"
            type="password"
            @input="inputEmitter"
        />
        <button
            class="form-field-password__show"
            :class="{ active: showPassword }"
            type="button"
            @click="() => (showPassword = !showPassword)"
        >
            <img :src="$assets.gray.eye" alt="icon" />
        </button>
        <div v-if="invalid" class="form-field-password__error">
            <img :src="$assets.red.formError" alt="icon" />
            <p>
                <slot></slot>
            </p>
        </div>
    </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue'

export default defineComponent({
    name: 'AuthInputPassword',
    props: {
        type: {
            type: String,
            default: 'text',
        },
        modelValue: {
            type: String,
        },
        invalid: {
            type: [Boolean, String],
            default: false,
        },
    },
    data() {
        return {
            showPassword: false,
        }
    },
    methods: {
        inputEmitter(e: Event) {
            this.$emit('update:modelValue', (e.target as HTMLInputElement).value)
        },
    },
})
</script>

<style lang="postcss" scoped>
.form-field-password {
    @apply relative text-lg;
    &__input {
        @apply block w-full rounded-xl border-transparent bg-gray-100 py-3 text-sm text-gray-800 placeholder-gray-400 ring-site-primary-hover-light focus:border-site-primary-hover-light focus:bg-white focus:text-site-primary focus:ring-1 focus:ring-site-primary-hover-light lg:hover:ring-1;
        min-width: theme('spacing.8');
        &.invalid {
            @apply mb-3 border-red-500 ring-1 ring-red-500;
        }
    }
    &__error {
        @apply flex items-center space-x-1.5 text-sm text-red-500;
        img {
            @apply w-4;
        }
    }
    &__show {
        @apply absolute right-3 top-3 h-5 w-5 transform opacity-80;
        img {
            @apply h-5 w-5;
        }
        &.active {
            @apply opacity-100;
        }
    }
}
</style>
